import React, { FC, useCallback, useState } from 'react';
import useTranslations, { TranslateFunction } from '@hooks/useTranslations';

import Icon, { Icons } from '../Icon';

import { SortBoxProps } from './index';
import { SortFilterKey } from '../../services/stock/stock.types';
import { getSelectedSortFilterKey, modifySortFilter } from '../../services/filters/utils/Filters.utils';
import {SortOrder, SortType, StockType} from '../../services/stock/models/stockItems.service.model';
import { useDispatch, useSelector } from 'react-redux';
import FilterDuck from '../../redux/filters/filter.duck';
import { Redux } from 'src/redux/redux.interface';
import { Trans } from 'react-i18next';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import { useRouter } from 'next/router';
import routes from '../../constants/routes';
import {STOCK_TYPE} from "../../services/filters/filters";

interface ISortOption {
    sortFilterKey: SortFilterKey;
    translation: string;
}

const getDefaultSortOptions: (t: TranslateFunction) => ISortOption[] = (t) => [
    {
        sortFilterKey: SortFilterKey.PRICE_ASC,
        translation: t('sortFilter.dropdown.key.priceASC'),
    },
    {
        sortFilterKey: SortFilterKey.PRICE_DESC,
        translation: t('sortFilter.dropdown.key.priceDESC'),
    },
];

const getProximitySortOptions: (t: TranslateFunction) => ISortOption[] = (t) => [
    {
        sortFilterKey: SortFilterKey.PROXIMITY_ASC,
        translation: t('sortFilter.dropdown.key.proximityASC'),
    },
];

const SortBoxTemplate: FC<SortBoxProps> = ({ className, filters, countOfVehicles, isSortEnabled }) => {
    const { t } = useTranslations();
    const router = useRouter();
    const dispatch = useDispatch();
    const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
    const distanceRadius = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = useCallback(
        (key: SortFilterKey) => {
            const [sortType, sortOrder] = key.split('-');

            const newFilters = modifySortFilter(filters, sortType as SortType, sortOrder as SortOrder);
            dispatch(FilterDuck.setFilters(newFilters, geoLocation, distanceRadius));

            setIsOpen(false);
        },
        [filters]
    );

    const selectedOption = getSelectedSortFilterKey(filters);
    const getSelectedOptionClassName = (key: SortFilterKey) =>
        key === selectedOption ? 'sort-box--selected-option' : '';

    const renderOption = ({ sortFilterKey, translation }: ISortOption) => (
        <li
            key={sortFilterKey}
            className={getSelectedOptionClassName(sortFilterKey)}
            onClick={() => handleChange(sortFilterKey)}
        >
            <AccessibleButton>{translation}</AccessibleButton>
        </li>
    );

    const renderOptions = () => {
        if (geoLocation) {
            const sortOptions = [...getProximitySortOptions(t), ...getDefaultSortOptions(t)];
            return sortOptions.map(renderOption);
        }

        return getDefaultSortOptions(t).map(renderOption);
    };

    const isSortBoxDisabledFromFS = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_IS_STOCK_SORT_BOX_DISABLED);

    return (
        <div className={className}>
            <h3
                className={`filter-title ${router.pathname.includes(routes.SELECTOR) ? 'isTrim' : 'isHome'}`}
                id="filter-title"
            >
                <span className="offer-list--vehicles-count">
                    <Trans t={t} i18nKey={`sortFilter.title${router.query[STOCK_TYPE] === StockType.VD ? '.demoVehicle' : ''}`} count={countOfVehicles}>
                        <b>{{ countOfVehicles }} véhicules</b> correspondent Á votre reche
                    </Trans>
                </span>
            </h3>
            {isSortEnabled && !isSortBoxDisabledFromFS && (
                <>
                    <span className="dropdown-title" onClick={() => setIsOpen(!isOpen)}>
                        <AccessibleButton>
                            {t('sortFilter.dropdown.title')} <Icon height={8} width={14} name={Icons.ChevronDown} />
                        </AccessibleButton>
                    </span>
                    <ul className={`dropdown-menu ${isOpen ? 'is-open' : ''}`}>{renderOptions()}</ul>
                </>
            )}
        </div>
    );
};

export default SortBoxTemplate;
